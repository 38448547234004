<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin w-sm-475px">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">Enter your username and password</p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          v-for="(error, i) in errors"
          :key="i"
          class="alert fade alert-danger"
        >
          <div class="alert-text">{{ error }}</div>
        </div>

        <b-form-group label-for="email">
          <template v-slot:label>
            Email
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            id="email"
            name="email"
            v-model="$v.form.email.$model"
            :state="validateState($v.form.email)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback"
            >Email is required and a valid email address.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label-for="password">
          <template v-slot:label>
            Password (6 characters minimum)
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            type="password"
            id="password"
            name="password"
            v-model="$v.form.password.$model"
            :state="validateState($v.form.password)"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">Password is required.</b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <router-link class="text-dark-60 text-hover-primary my-3 mr-2" to="/forgot-password">
            Forgot Password ?
          </router-link>
          <button v-cs-loading="submiting" class="ml-auto btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { LOGIN, LOGOUT } from '@/store/modules/auth';
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'login',
  data() {
    return {
      submiting: false,
      form: {
        email: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors || [],
    }),
  },
  mounted() {
    this.setErrors(null);
  },
  methods: {
    ...mapActions({
      getNotifications: 'notifications/index',
    }),
    ...mapMutations({
      setErrors: 'auth/SET_ERROR',
    }),

    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      this.submiting = true;

      this.$store
        .dispatch(LOGIN, this.form)
        // go to which page after successfully login
        .then(() => {
          if (!this.isApplicant) {
            this.getNotifications();
          }
          this.$router.push({ name: 'dashboard' });
        })
        .finally(() => (this.submiting = false));
    },
  },
};
</script>
